export default defineNuxtRouteMiddleware(() => {
  const { appUserMe } = useAppUserStore(usePinia());
  const appUserHelpers = useAppUser();
  const isAdmin = appUserHelpers.isAdmin(appUserMe);
  if (!isAdmin) {
    return abortNavigation({
      statusMessage: "Unauthorized",
      message: "You need to have admin rights to access this page.",
    });
  }
});
